import React, { Component } from 'react'
import { View } from 'react-native'
import { Table, Button, Form, Row, Col, Modal, Nav, } from "react-bootstrap";

export default class LessonComponent extends Component {

    state = {
        removeModal: false,
        isOpen: false,
    }    

    render() {
        return (
            <>
                <tr key={this.props.index} onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
                    <td>{this.props.index + 1}</td>
                    <td>
                        {this.props.lessonName ? this.props.lessonName : ''}
                    </td>
                    <td>
                        {this.props.teacherName ? this.props.teacherName : ''}
                    </td>
                    <td>
                        {this.props.lessonHour ? this.props.lessonHour : ''}
                    </td>

                    <td>
                        <Button
                            variant="danger"
                            onClick={() => {

                            }}
                        >
                            Sil
                        </Button>
                    </td>



                </tr>

                {
                    this.state.isOpen && (
                        <tr>
                            <td colSpan={5}>

                                <Table striped bordered hover className="mt-3">

                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Değerlendirme</th>
                                            <th>Katsayı</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            this.props.evaluations?.map((item, index) => {


                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <Form.Check
                                                                type='checkbox'
                                                                onChange={(e) => {
                                                                    let copyItem = { ...item }
                                                                    copyItem.degree = 0
                                                                    this.props.addOrRemoveEvaluation(copyItem)
                                                                }}
                                                                checked={this.props.selectedItems.findIndex((selectItem)=>selectItem.evaluation.id === item.id) > -1 ? true : false}
                                                            />
                                                        </td>
                                                        <td>{item.name}</td>
                                                        <td>
                                                            <Form.Control
                                                                type="number"
                                                                placeholder="Kat Sayı"
                                                                value={this.props.selectedItems.find((selectItem)=>selectItem.evaluation.id === item.id)?.degree}
                                                                disabled={this.props.selectedItems.findIndex((selectItem)=>selectItem.evaluation.id === item.id) > -1 ? false : true}
                                                                onChange={(e) => {
                                                                    this.props.onChangeDegree(item, e.target.value)
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>

                                </Table>
                            </td>

                        </tr>
                    )
                }


            </>
        )
    }
}
